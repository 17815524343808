import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import ContactMap from "../components/contact/map"
import Button from "../components/elements/button"
import { set, useForm } from "react-hook-form"
import db from "../firebase-config"
import firebase from "firebase/compat/app"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Fade from "react-reveal/Fade"

const Contact = ({ data }) => {
  const [success, setSuccess] = useState(false)

  const images = {
    hero: convertToBgImage(getImage(data.hero)),
  }

  function toastHandleChange(newValue) {
    setSuccess(newValue)
  }

  const notify = () => {
    toast.success("Thank you for getting in touch!")
  }

  if (success) {
    notify()
    setTimeout(function () {
      setSuccess(false)
    }, 5000)
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <BackgroundImage
        {...images.hero}
        className="bg-primary-brown py-72 w-full"
      >
        <Fade bottom>
          <div className="lg:text-8xl text-7xl text-white text-center font-prata gap-6">
            Contact Us.
          </div>
        </Fade>
      </BackgroundImage>
      <section>
        <div className="">
          <div className="lg:flex-row flex-col flex">
            <div className="lg:w-1/2 w-full pl-[10%]">
              <div className="py-24 pr-[10%] lg:pr-[20%]">
                <Fade bottom cascade>
                  <div>
                    <div className="font-prata  lg:text-5xl text-4xl lg:text-left   text-black mb-5">
                      Talk to us
                    </div>
                  </div>
                  <div>
                    <div className="font-light mb-14 text-black lg:text-left ">
                      Thank you for reaching us. We will get back to you within
                      24 hours with confirmation
                    </div>
                  </div>
                </Fade>
                <Form success_state={success} onChange={toastHandleChange} />
              </div>
            </div>
            <div className="lg:w-1/2 w-full hidden lg:block">
              <ContactMap />
            </div>
          </div>
        </div>
      </section>
      <div>
        {success && (
          <div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </div>
    </Layout>
  )
}

const Form = props => {
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  function parentState() {
    props.onChange(true)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const submitContactRequest = async data => {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    newData.isCompleted = false
    await db.collection("contacts").add(newData)
  }

  const sendGridFirebase = async data => {
    fetch(
      "https://us-central1-vaultcoffee.cloudfunctions.net/contacts",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      await submitContactRequest(data)
      await sendGridFirebase(data)
      parentState()
      setLoading(false)
      // setSuccess(true)
      setSubmitError(null)
      reset()
    } catch (err) {
      console.log(err.message)
      setLoading(false)
      setSubmitError("Submit Error occurred!")
      // setSuccess(false)
    }
  }

  return (
    <Fade bottom cascade>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-10">
          <div className="font-light">Your name</div>
          <input
            type="text"
            name="name"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="Enter your name"
            {...register("name", {
              required: true,
              pattern: {
                value: /^[a-z ,.'-]+$/i,
                message: "You Can Only Use Letters",
              },
            })}
          />
          {errors.name ? (
            <>
              {errors.name.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.name.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Name cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="mb-10">
          <div className="font-light">Email address</div>
          <input
            type="text"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="Enter your email"
            name="email"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Email address is not valid",
              },
            })}
          />
          {errors.email ? (
            <>
              {errors.email.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.email.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Email cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="mb-10">
          <div className="font-light">Contact number</div>
          <input
            type="text"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light"
            placeholder="Enter your contact number"
            name="number"
            {...register("number", {
              required: true,
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                message: "Number is not valid",
              },
            })}
          />
          {errors.number ? (
            <>
              {errors.number.message ? (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    {errors.number.message}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xs text-red-400 font-firs-light mt-1">
                    Number cannot be empty
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
        <div className="mb-10">
          <div className="font-light">Message</div>
          <textarea
            type="text"
            className="border-b-black border-b py-3 w-full focus:outline-none font-light resize-none"
            placeholder="Enter your message"
            name="message"
            {...register("message", {
              required: true,
            })}
            rows="5"
          ></textarea>
          {errors.message ? (
            <>
              <div className="text-xs text-red-400 font-firs-light mt-1 mb-5">
                Message cannot be empty
              </div>
            </>
          ) : null}
        </div>
        <button
          className="bg-primary-brown px-10 py-3 border border-primary-brown text-white w-full hover:px-12 duration-500 text-center flex justify-center hover:w-11/12 mx-auto cursor-pointer"
          type="submit"
        >
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <div>Submit</div>
          )}
        </button>
      </form>
    </Fade>
  )
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-5 h-5 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export default Contact

export const query = graphql`
  query AboutImages {
    hero: file(relativePath: { eq: "pages/contact/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
