import React, { Component } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

class ContactMap extends Component {
  lat = "40.402306201727534"
  long = "-79.77666897304034"

  componentDidMount() {
    var mapSelector = document.querySelector(
      "#gatsby-focus-wrapper > main > section > div > div > div:nth-child(2) > div > div:nth-child(1) > div"
    )
    mapSelector.style.width = "50%"
  }
  render() {
    const lat = this.lat
    const long = this.long
    function onMarkerClick() {
      var url = "https://maps.google.com/?q=" + lat + "," + long
      console.log(url)
      window.open(url)
    }
    return (
      <div className="">
        <Map
          google={this.props.google}
          zoom={14}
          style={{
            width: "100%",
            height: "114.8% ",
            overflow: "hidden",
          }}
          initialCenter={{
            lat: this.lat,
            lng: this.long,
          }}
        >
          <Marker onClick={onMarkerClick} name={"Current location"} />

          <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBhU_jWkKgDPoz2tJYxbjRLxy3YWuRo2SY",
})(ContactMap)
